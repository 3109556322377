import request from "@/utils/request";

// 生产看板
export function productionProgressStats(params) {
  return request({ url: `/production_kanban/production_progress_stats/`, method: "get", params });
}

export function materialPreparationStats(params) {
  return request({ url: `/production_kanban/material_preparation_stats/`, method: "get", params });
}
